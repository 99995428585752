import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const feedProvider = {
    getLayout(success: Function, error?: Function){
        Client().get(process.env.REACT_APP_CMS_API_URL+'/react/feed/layout')
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    getData(page: number, success: Function, error?: Function){
        Client().get(process.env.REACT_APP_CMS_API_URL+'/react/feed?page=' + page)
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    getLastComments(success: Function, error?: Function){
        Client().request({
            url: process.env.REACT_APP_CMS_API_URL+'/react/comments/last-comments',
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default feedProvider;