import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { FeedItem, TYBrandingData } from "../../../../types/types";

import ItemHeader from "./itemHeader";

type TyCardsItemTypeProps = {
  item: FeedItem;
  isLastItem?: boolean;
  tyBranding: TYBrandingData;
};

const TyCardsItemType: FunctionComponent<TyCardsItemTypeProps> = ({
  item,
  tyBranding,
  isLastItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tyCardNavigation = (e: any) => {
    e.preventDefault();
    navigate(`/awards/${item.model.id}`);
  };

  const navigateToSender = (e: any, userId: number) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/connect/people/${userId}`);
  };

  return (
    <a
      href={`/awards/${item.model.id}`}
      className={`d-block text-decoration-none ${isLastItem ? '' : 'mb-2'}`}
      data-testid="ty-card-link"
    >
      <Card className={`p-3 item-container ${isLastItem ? '' : 'mb-3'}`} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)', borderRadius: '10px' }} data-testid="ty-card-item">
        <div className="c-pointer" onClick={tyCardNavigation} data-testid="ty-card-item-header">
          <ItemHeader
            imgUrl={item.model.sender.image_url}
            headText={item.model.sender.full_name}
            jobTitle={item.model.sender.jobtitle}
            iconClass={tyBranding.tycIcon}
            time={item.model.created_at_humans || ""}
            contact_id={""}
          />
        </div>

        {item.model && item.model.image_url && (
          <div className="media media-news mt-1">
            <div className="float-left me-3 d-flex justify-content-center align-items-flex-start w-auto h-auto">
              <img
                src={item.model.image_url}
                alt="Image"
                className="media-object img-rad"
                style={{ maxHeight: "200px" }}
                data-testid="ty-card-img"
              />
            </div>
            <div className="media-body clearfix ms-20">
              <h3 className="mb-3 text-light-black mt-3 font-primary-text">
                {Array.isArray(item.model.recipients) ? (
                  item.model.recipients.map((element: any) => {
                    return (
                      <span
                        key={element.id}
                        className="sender-name c-pointer"
                        onClick={(e: any) =>
                          navigateToSender(e, element.contact_id)
                        }
                        data-testid="ty-card-recipients-name"
                      >
                        @{element.full_name || ""}&nbsp;
                      </span>
                    );
                  })
                ) : (
                  <span
                  className="sender-name c-pointer"
                  onClick={(e: any) =>
                    navigateToSender(e, item.model.recipients.contact_id)
                  }
                  data-testid="ty-card-recipient-name"
                  >
                    @{item.model.recipients.full_name || ""}&nbsp;
                  </span>
                )}
                {`${t("feed.received", { text: item.model.tyc_label })} ${t('awards.for')} ${item.model.image_title
                  }`}
                :
              </h3>
              <h3 className="text-light-black m0 fw-normal font-secondary-text" data-testid="ty-card-message">
                {item.model.message || ""}
              </h3>
              {item.model.manager_name !== "" ? (
                <div className={"mt-3"}>
                  <h3 className={"text-light-black font-primary-text"}>
                    {t("feed.messageToManager")}
                  </h3>
                  <div className="mt-2 border-bottom" />
                  <div className={"mt-2"}>
                    <span
                      className="sender-name text-light-black font-secondary-text"
                      style={{ fontSize: "1.125rem" }}
                      data-testid="ty-card-manager-message"
                    >
                      {item.model.manager_message}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </Card>
    </a>
  );
};

export default TyCardsItemType;
