export default {
  "yes": "Yes",
  "no": "No",
  "ok": "OK",
  "cancel": "Cancel",
  "hour": "Hour",
  "hours": "Hours",
  "week": "Week",
  "month": "Month",
  "year": "Year",
  "tyCard": "Thank You Card",
  "tyCards": "Thank You Cards",
  "send": "Send",
  "public": "Public",
  "private": "Private",
  "like": "Like",
  "likes": "Likes",
  "comment": "Comment",
  "comments": "Comments",
  "reply": "Reply",
  "share": "Share",
  "person": "person",
  "people": "people",
  "selected": "selected",
  "search": "Search",
  "errors": {
    "errorLoadingData": "Error loading data",
    "errorLoadingPage": "Error loading page",
    "errorReload": "Error loading menu, please reload the page"
  },
  "success": {
    "general": "Action was successful"
  },
  "noData": "There are no posts to show right now, check back later for updates"
};
