import React, { FunctionComponent } from "react";
import "../customLayout.scss";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import {ContentData} from "~/types/types";

type FolderTileProps = {
  content: ContentData;
};

const FolderTile: FunctionComponent<FolderTileProps> = ({ content }) => {
  return (
    <>
      <Card
        id={`folder_${content.id}`}
        data-testid="folder-tile-card"
        style={{
          borderRadius: 10,
          overflow: "hidden",
        }}
        onClick={() => {
          content.web_layout === "list"
            ? (window.location.href = `${window.location.origin}/category/${content.id}`)
            : content.web_layout === "tile"
            ? (window.location.href = `${window.location.origin}/tile/category/${content.id}`)
            : (window.location.href = `${window.location.origin}/custom/category/${content.id}`);
        }}
        key={content.id}
      >
        <img
          data-testid="content-image"
          className="card-img-top preview-image"
          src={content.image}
          alt="tile-card"
        />
        <CardBody>
          <CardTitle tag="h4">
            <strong data-testid="content-title" className="font-black" data-cy="title-text">{content.title}</strong>
          </CardTitle>
          <div style={{ marginBottom: "10px" }}>
            <i
              className="fas fa-folder-open fa-2x mb-20 title-tile font-black"
              title="folder-tile-icon"
            />
          </div>
          <CardText>
            <small className="font-black" data-testid="content-subtitle">{content.subtitle}</small>
          </CardText>
        </CardBody>
      </Card>
    </>
  );
};

export default FolderTile;
