import "./breadcrumbs.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem }                    from "reactstrap";
import { Breadcrumb as EngageBreadcrumb, ContentType }   from "~/types/types";
import breadcrumbsService                                from "../../services/breadcrumbsService";
import { AxiosResponse }                                 from "axios";

type BreadcrumbProps = {
  contentType: ContentType;
  contentId: number;
};

const Breadcrumbs: FunctionComponent<BreadcrumbProps> = ({ contentType, contentId }) => {
  const [data, setBreadcrumbsData] = useState<EngageBreadcrumb[]>([]);
  
const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [contentId, contentType]);
  
  const fetchData = () => {
    breadcrumbsService.getData(
      contentId,
      contentType,
      (response: AxiosResponse) => {
        let breadcrumbs = response.data[0]?.data.breadcrumbs;
        if (breadcrumbs) {
          setBreadcrumbsData(breadcrumbs);
        }
      }
    );
  };
  
  return (
    <div className="col-xs-12 col-md-12 p-0">
      <Breadcrumb data-testid="breadcrumbs" className="breadcrumb-wrapper">
        {data.map((breadcrumb, index) => {
            const urlPath =
              breadcrumb.url !== "" ? new URL(breadcrumb.url) : null;
            return (
              <React.Fragment key={index}>
                {urlPath === null ? (
                  <BreadcrumbItem active className="breadcrumb-item">
                    <strong data-testid="active-breadcrumb-item">{breadcrumb.label}</strong>
                  </BreadcrumbItem>
                ) : (
                  <BreadcrumbItem className="breadcrumb-item">
                    <a data-testid="breadcrumb-item" className="breadcrumb-item-a" onClick={()=> {navigate(urlPath.pathname)}}>
                      <strong >{breadcrumb.label}</strong>
                    </a>
                  </BreadcrumbItem>
                )}
              </React.Fragment>
            );
        })}
      </Breadcrumb>
      <hr className="solid" />
    </div>
  );
};

export default Breadcrumbs;
