export default {
  "repliedToYourComment": "Respondió a su comentario",
  "likedYourComment": "Respondió a su comentario",
  "sentYou": "Te envió {{text}}",
  "received": "Recibido {{text}}",
  "sendEmail": "Correo Electrónico",
  "sendMessage": "Mensaje",
  "createThread": "Crear Nuevo Hilo",
  "attachments": "Archivos Adjuntos",
  "done": "Hecho",
  "expand": "Expandir",
  "hide": "Ocultar",
  "showMoreComments": "Mostrar Más Comentarios",
  "hideComments": "Ocultar Comentarios",
  "showComments": "Mostrar Comentarios",
  "hideReplies": "Ocultar Respuestas",
  "showReplies": "Mostrar Respuestas",
  "deleteComment": "Eliminar Comentario",
  "deleteCommentDesc": "¿Esta seguro de que quiere eliminar este comentario?",
  "noCommentsYet": "Todavía no hay comentarios...",
  "chooseAction": "Elige una acción",
  "deletePost": "Eliminar",
  "deletePostDesc": "¿Esta seguro de que quiere eliminar este contenido?",
  "Unfollow": "Dejar de Seguir",
  "snooze": "Dormitar",
  "reportPost": "Denunciar Publicación",
  "report": "Denunciar",
  "reportReason": "Motivo para denunciar esta publicación:",
  "messageToManager": "Mensaje al superior",
  "delete": "Eliminar",
  "emptyAwardsMessage": "¡No hay nada que mostrar aquí, aún no has recibido nada!",
  "Followers": "Seguidores",
  "Following": "Siguiendo",
  "SendAThankYou": "Enviar un {{label}}",
  "SeeAll": "Ver todo",
  "SentToYou": "Enviado a ti",
  "CreateASocialPost": "Crear una publicación",
  "Photo": "Foto",
  "Video": "Video",
  "Files": "Archivos",
  "Post": "Publicación",
  "LatestComments": "Últimos comentarios",
  "SentBy": "Enviado Por"
};