export default {
  "yes": "Si",
  "no": "No",
  "ok": "OK",
  "cancel": "Cancel",
  "hour": "Hora",
  "hours": "Horas",
  "week": "Semana",
  "month": "Mes",
  "year": "Año",
  "tyCard": "Tarjeta de agradecimiento",
  "tyCards": "Tarjetas de agradecimiento",
  "send": "Enviar",
  "public": "Pública",
  "private": "Privada",
  "like": "Me gusta",
  "likes": "Me gusta",
  "comment": "Comentario",
  "reply": "Respuesta",
  "comments": "Comentarios",
  "share": "Compartir",
  "person": "persona",
  "people": "personas",
  "selected": "seleccionada",
  "search": "Buscar",
  "errors": {
    "errorLoadingData": "​Error al cargar los datos",
    "errorLoadingPage": "Error al cargar la página",
    "errorReload": "Error al cargar el menú, por favor recarge la página"
  },
  "success": {
    "general": "La acción se ha ejecutado correctamente"
  },
  "noData": "No hay publicaciones para mostrar en este momento, vuelva más tarde para ver las actualizaciones"
};