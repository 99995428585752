import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const connectProvider = {
    getFavourites(success: Function, error?: Function) {
        Client()
            .get(process.env.REACT_APP_CMS_API_URL + '/react/connect/favorites')
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getLastComments(success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + '/react/comments/last-comments',
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getMoreUsersData(page: number, success: Function, error?: Function) {
        Client()
            .get(process.env.REACT_APP_CMS_API_URL + '/react/connect?page=' + page)
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getLocationListingData(page: number, success: Function, error?: Function) {
        Client()
            .get(process.env.REACT_APP_CMS_API_URL + '/react/connect/locations?page=' + page)
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getFilteredContactData(page: number, data: any, success: Function, error?: Function) {
        Client(true, true)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect?page=${page}`,
                method: 'post',
                data: data,
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getContactById(id: string, success: Function, error?: Function) {
        Client()
            .get(process.env.REACT_APP_CMS_API_URL + '/react/connect/' + id)
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    updateFavouritesList(id: string, data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/` + id + '/favorite',
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    followUserRequest(id: string, data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/user/follow`,
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    unfollowUserRequest(id: string, data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/user/unfollow`,
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    setSnoozeTime(id: string, data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/user/snooze`,
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getPostData(userId: string, pageNo: number, success: Function, error?: Function) {
        Client()
            .get(process.env.REACT_APP_CMS_API_URL + '/react/user/' + userId + '/posts' + '?page=' + pageNo)
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    likePost(postId: number, isLiked: boolean, success: Function, error?: Function) {
        Client()
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/like`,
                method: 'post',
                data: {
                    id: postId,
                    is_liked: isLiked,
                },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    saveProfileImage(data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/image`,
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },
    saveProfileHeaderImage(data: any, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/header`,
                method: 'post',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getLoggedInUserData(success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + '/react/connect/current',
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getUserOrgChartById(id: string, success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + '/react/user/' + id + '/orgchartdata',
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getThreadByUserId(id: number, success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + '/react/connect/' + id + '/message',
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getEditData(id: string, success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + '/react/connect/' + id + '/edit',
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    updateProfile(data: any, id: number, success: Function, error?: Function) {
        Client(true, false)
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/` + id + `/edit`,
                method: 'patch',
                data: data,
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getConnectFilters(page: number, data: any, success: Function, error?: Function) {
        Client()
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/filters?page=${page}&type=${data.type}&search=${data.search}`,
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getAttributes(page: number, success: Function, error?: Function) {
        Client()
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/attributes?page=${page}`,
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getFollowers(id: number, page: number, success: Function, error?: Function) {
        Client()
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/${id}/followers?page=${page}`,
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },

    getFollowings(id: number, page: number, success: Function, error?: Function) {
        Client()
            .request({
                url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/${id}/following?page=${page}`,
                method: 'get',
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },
};

export default connectProvider;
