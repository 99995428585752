import connectProvider from '../providers/connectProvider';

const connectService = {
    getFavourites(success: Function, error?: Function) {
        connectProvider.getFavourites(success, error);
    },

    getLastComments(success: Function, error?: Function) {
        connectProvider.getLastComments(success, error);
    },

    getMoreUsersData(page: number, success: Function, error?: Function) {
        connectProvider.getMoreUsersData(page, success, error);
    },

    getLocationListingData(page: number, success: Function, error?: Function) {
        connectProvider.getLocationListingData(page, success, error);
    },

    getFilteredContactData(page: number, data: any, success: Function, error?: Function) {
        connectProvider.getFilteredContactData(page, data, success, error);
    },

    getContactById(id: string, success: Function, error?: Function) {
        connectProvider.getContactById(id, success, error);
    },

    updateFavouritesList(id: string, data: any, success: Function, error?: Function) {
        connectProvider.updateFavouritesList(id, data, success, error);
    },

    followUserRequest(id: string, data: any, success: Function, error?: Function) {
        connectProvider.followUserRequest(id, data, success, error);
    },

    unfollowUserRequest(id: string, data: any, success: Function, error?: Function) {
        connectProvider.unfollowUserRequest(id, data, success, error);
    },

    setSnoozeTime(id: string, data: any, success: Function, error?: Function) {
        connectProvider.setSnoozeTime(id, data, success, error);
    },

    getPostData(userId: string, pageNo: number, success: Function, error?: Function) {
        connectProvider.getPostData(userId, pageNo, success, error);
    },

    likePost(postId: number, isLiked: boolean, success: Function, error?: Function) {
        connectProvider.likePost(postId, isLiked, success, error);
    },

    saveProfileImage(data: any, success: Function, error?: Function) {
        connectProvider.saveProfileImage(data, success, error);
    },

    saveProfileHeaderImage(data: any, success: Function, error?: Function) {
        connectProvider.saveProfileHeaderImage(data, success, error);
    },

    getLoggedInUserData(success: Function, error?: Function) {
        connectProvider.getLoggedInUserData(success, error);
    },

    getUserOrgChartById(id: string, success: Function, error?: Function) {
        connectProvider.getUserOrgChartById(id, success, error);
    },

    getThreadByUserId(id: number, success: Function, error?: Function) {
        connectProvider.getThreadByUserId(id, success, error);
    },

    getEditData(id: string, success: Function, error?: Function) {
        connectProvider.getEditData(id, success, error);
    },

    updateProfile(data: any, id: number, success: Function, error?: Function) {
        connectProvider.updateProfile(data, id, success, error);
    },

    getConnectFilters(page: number, data: any, success: Function, error?: Function) {
        connectProvider.getConnectFilters(page, data, success, error);
    },

    getAttributes(page: number, success: Function, error?: Function) {
        connectProvider.getAttributes(page, success, error);
    },

    getFollowers(id: number, page: number, success: Function, error?: Function) {
        connectProvider.getFollowers(id, page, success, error);
    },

    getFollowings(id: number, page: number, success: Function, error?: Function) {
        connectProvider.getFollowings(id, page, success, error);
    },
};

export default connectService;
