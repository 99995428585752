export default {
  "yes": "sì",
  "no": "No",
  "ok": "OK",
  "cancel": "Annulla",
  "hour": "Ora",
  "hours": "Ore",
  "week": "Settimana",
  "month": "Mese",
  "year": "Anno",
  "tyCard": "Biglietto di ringraziamento",
  "tyCards": "Biglietti di ringraziamento",
  "send": "Invia",
  "public": "Pubblico",
  "private": "Privato",
  "like": "Mi piace",
  "likes": "Mi piace",
  "comment": "Commento",
  "comments": "Commenti",
  "reply": "Rispondi",
  "share": "Condividi",
  "person": "persona",
  "people": "persone",
  "selected": "selezionato",
  "search": "Cerca",
  "errors": {
    "errorLoadingData": "Errore durante il caricamento dei dati",
    "errorLoadingPage": "Errore durante il caricamento della pagina",
    "errorReload": "Errore durante il caricamento del menu, ricarica la pagina"
  },
  "success": {
    "general": "L'azione ha avuto successo"
  },
  "noData": "Non ci sono post da mostrare in questo momento, ricontrolla più tardi per gli aggiornamenti"
};