import { createTheme } from '@mui/material';

export const Theme = createTheme({
    typography: {
        fontFamily: '"Gilroy", sans-serif',
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    lineHeight: 1.2,
                    fontSize: '14px',
                    fontWeight: 400,
                    fontFamily: 'Gilroy',
                },
            },
        },
    },
});
