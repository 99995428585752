import React from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

export type ModalImageListProps = {
    files: any;
    handleClick: (url: string, index: number) => void;
    sx?: SxProps;
};

export const ModalImageList = (props: ModalImageListProps) => {
    const { files, handleClick, sx } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'scroll',
                maxWidth: '60%',
                marginBottom: '10px',
                ...sx,
            }}
            gap={2}
            data-testid="modal-image-list"
        >
            {files?.map((file: any, index: number) => {
                return (
                    <Box sx={{ display: 'block', width: '100px', height: '100px' }}>
                        <img
                            src={file?.preview}
                            key={index}
                            style={{
                                width: '75px',
                                height: '75px',
                                objectFit: 'cover',
                                cursor: 'pointer',
                                borderRadius: '15px',
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                                handleClick(file?.type.includes('image') || file?.type.includes('video') ? file?.path : file?.preview, index)
                            }
                            onKeyDown={() =>
                                handleClick(file?.type.includes('image') || file?.type.includes('video') ? file?.path : file?.preview, index)
                            }
                            data-testid="modal-image-list-img"
                        />
                        <Typography sx={{ fontSize: '10px', color: '#fff', maxWidth: '75px', marginTop: '5px' }}>
                            {!file?.type.includes('image') && file?.name}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};
